import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { useSnackbar } from "notistack";
import NavMenu from "../NavMenu";
import PrivateRoute from "../RouterLayouts/PrivateRoute/PrivateRoute";
import Statistic from "../../pages/Statistic";
import Posts from "../../pages/Posts";
import Users from "../../pages/Users";
import PostItem from "../../pages/Posts/PostItem";
import CreatePost from "../../pages/Posts/CreatePost/CreatePost";
import User from "../../pages/Users/User";
import ChatRelease from "../../pages/ChatRelease";
import ChatReleaseCreate from "../../pages/ChatRelease/ChatReleaseCreate";
import NoFindPage from "../../pages/404";

import { useApi } from "../../hooks/useApi";
import useCommon from "../../hooks/useCommon";
import useGlobalCounters from "../../hooks/useGlobalCounters";
import GlobalSockets from "../GlobalSockets/GlobalSockets";
import CreateUser from "../../pages/Users/User/CreateUser";
import Subscribers from "../../pages/Subscribers";
import Feedback from "../../pages/Feedback";
import SupportChat from "../../pages/SupportChat/SupportChat";
import Analytics from "../../pages/Analytics";
import OnboardingData from "../../pages/OnboardingData";
import UsersList from "../../pages/UsersList";

const Dashboard = () => {
  const api = useApi();
  const { setProfile } = useCommon();
  const { setSupportCounter } = useGlobalCounters();
  const { enqueueSnackbar } = useSnackbar();
  const { profile } = useCommon();

  const getProfile = async () => {
    try {
      const { data } = await api.get("/profile");
      setProfile?.(data);
    } catch (err) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const getGlobalCounters = async () => {
    try {
      const { data } = await api.get("/admin/global-counters");
      setSupportCounter?.(data.count_unread_message);
    } catch (err) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  useEffect(() => {
    getProfile();
    getGlobalCounters();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "background.default",
        color: "text.primary",
        borderRadius: 1,
        p: 3,
        paddingTop: "80px",
      }}
    >
      <NavMenu />
      {profile && <GlobalSockets />}

      <Routes>
        <Route
          path="/users-list"
          element={
            <PrivateRoute>
              <UsersList />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Statistic />
            </PrivateRoute>
          }
        />
        <Route
          path="/posts"
          element={
            <PrivateRoute>
              <Posts />
            </PrivateRoute>
          }
        />
        <Route
          path="/posts/create"
          element={
            <PrivateRoute>
              <CreatePost />
            </PrivateRoute>
          }
        />
        <Route
          path="/posts/:id"
          element={
            <PrivateRoute>
              <PostItem />
            </PrivateRoute>
          }
        />

        <Route path="/users">
          <Route
            path="create"
            element={
              <PrivateRoute>
                <CreateUser />
              </PrivateRoute>
            }
          />
          <Route
            path=":id"
            element={
              <PrivateRoute>
                <User />
              </PrivateRoute>
            }
          />
          <Route
            path=""
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
        </Route>

        <Route
          path="/subscriptions"
          element={
            <PrivateRoute>
              <Subscribers />
            </PrivateRoute>
          }
        />

        <Route
          path="/onboarding-data"
          element={
            <PrivateRoute>
              <OnboardingData />
            </PrivateRoute>
          }
        />

        <Route
          path="/feedback"
          element={
            <PrivateRoute>
              <Feedback />
            </PrivateRoute>
          }
        />
        <Route
          path="/analytics"
          element={
            <PrivateRoute>
              <Analytics />
            </PrivateRoute>
          }
        />

        <Route path="/chat_update">
          <Route
            path="create/:id"
            element={
              <PrivateRoute>
                <ChatReleaseCreate />
              </PrivateRoute>
            }
          />
          <Route
            path=""
            element={
              <PrivateRoute>
                <ChatRelease />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="/chat_support">
          <Route
            path=":id"
            element={
              <PrivateRoute>
                <SupportChat />
              </PrivateRoute>
            }
          />
          <Route
            path=""
            element={
              <PrivateRoute>
                <SupportChat />
              </PrivateRoute>
            }
          />
        </Route>

        <Route path="*" element={<NoFindPage />} />
      </Routes>
    </Box>
  );
};

export default Dashboard;
