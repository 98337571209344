import { plansKey } from "../../../OnboardingData/titleKeyObject.types";

export const itemTitleKey: { [key: string]: string } = {
  checklists: "checklists",
  shared_checklist: "shared checklist",
  shared_with_checklist: "shared with checklist",
  items: "items",
  events: "events",
  events_recurred: "events recurred",
  shared_events: "shared events",
  shared_events_recurred: "shared events recurred",
  tasks: "tasks",
  tasks_recurred: "tasks recurred",
  shared_tasks: "shared tasks",
  shared_tasks_recurred: "shared tasks recurred",
  shared_items: "shared items",
  insurances: "insurances",
  licenses: "licenses",
  stickers: "stickers",
  login_tokens: "login tokens",
  register_tokens: "register tokens",
  reset_tokens: "reset tokens",
  tokens: "tokens",
  contacts: "contacts",
  shared_contacts: "shared contacts",
  shared_with_contacts: "shared with contacts",
  added_friends: "added friends",
  ...plansKey,
};
