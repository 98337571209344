import {
  Box,
  Button,
  Card,
  Fade,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useCallback, useEffect, useState } from "react";

import { useDebouncedCallback } from "use-debounce";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import useSWR, { useSWRConfig } from "swr";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { useModal } from "mui-modal-provider";
import ConfirmModal from "../../components/modals/ConfirmModal";
import EditRubricTagDialog from "../../components/modals/EditRubricTagModal";
import { useApi, useFetcher } from "../../hooks/useApi";

import PostRow from "./PostRow";
import { Post, Tags } from "./Post.types";
import useCommon from "../../hooks/useCommon";
const Posts = () => {
  const fetcher = useFetcher();
  const api = useApi();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [orderBy, setOrderBy] = useState<"desc" | "asc">("asc");
  const [sortBy, setSorBy] = useState("");
  const [search, setSearch] = useState("");
  const [startDay, setStartDay] = useState("");
  const [endDay, setEndDay] = useState("");
  const { setBreadCrumbs } = useCommon();
  const { enqueueSnackbar } = useSnackbar();

  const replaceUndefinedOrNull = (key: string, value: any) => {
    if (value === null || value === undefined || value === "") {
      return undefined;
    }

    return value;
  };

  useEffect(() => {
    setBreadCrumbs?.([{ label: "Posts" }]);
  }, [setBreadCrumbs]);

  const { showModal } = useModal();

  const searchParams = useCallback(() => {
    return JSON.parse(
      JSON.stringify(
        {
          per_page: perPage,
          order_by: orderBy.toLocaleUpperCase(),
          page: page + 1,
          sort_by: sortBy,
          search,
          start_date: startDay,
          end_date: endDay,
        },
        replaceUndefinedOrNull
      )
    );
  }, [perPage, orderBy, page, sortBy, search, startDay, endDay]);

  const { mutate } = useSWRConfig();

  const { data } = useSWR(["/blog/posts", searchParams()], fetcher);
  const { data: allTags } = useSWR("/tags", fetcher);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSort = (property: string) => () => {
    const isAscending = sortBy === property && orderBy === "asc";
    setSorBy(property);
    setOrderBy(isAscending ? "desc" : "asc");
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSearch = useDebouncedCallback((value) => {
    setSearch(value);
    setSorBy("");
    setOrderBy("asc");
  }, 1000);

  const clearSearchParams = () => {
    setSearch("");
    setStartDay("");
    setEndDay("");
  };

  const handleDelete = async (id: number) => {
    try {
      await api.delete(`/blog/posts/${id}`);
      await mutate(["/blog/posts", searchParams()]);
      enqueueSnackbar("Post successful deleted", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar("Something want wrong", {
        variant: "error",
      });
    }
  };

  const handleDeleteTag = async (id: number) => {
    try {
      await api.delete(`/tags/${id}`);

      await mutate("/tags");
      await mutate(["/blog/posts", searchParams()]);

      enqueueSnackbar("Rubric successful deleted", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar("Something want wrong", {
        variant: "error",
      });
    }
  };

  const skeletonArray = Array(10).fill(Array(5).fill(""));

  const handleOpenConfirmationDialog = (id: number, name: string) => {
    const modal = showModal(ConfirmModal, {
      title: `Delete rubric "${name}"`,
      body: "Are you sure you want to delete this rubric?",
      onConfirm: () => {
        handleDeleteTag(id);
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
    });
  };

  const handleOpenEditTagDialog = (name: string, id: number | null) => {
    const modal = showModal(EditRubricTagDialog, {
      editData: { name },
      tagId: id,
      onUpdate: () => {
        mutate("/tags");
        mutate(["/blog/posts", searchParams()]);
      },
      onCancel: () => {
        modal.hide();
      },
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Card>
        <Toolbar>
          <Box
            sx={{
              width: "100%",
              margin: "20px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                sx={{ width: 220 }}
                id="type_id"
                placeholder="Search..."
                label="Search"
                variant="standard"
                onChange={(e) => handleChangeSearch(e.target.value)}
                fullWidth
              />
              <TextField
                id="date"
                label="Start date"
                type="date"
                value={startDay}
                variant="standard"
                onChange={(e) => setStartDay(e.target.value)}
                sx={{ width: 150, marginLeft: "20px" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="date"
                label="End date"
                variant="standard"
                type="date"
                value={endDay}
                onChange={(e) => setEndDay(e.target.value)}
                sx={{ width: 150, marginLeft: "20px" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Fade in={!!search || !!startDay || !!endDay}>
                <Box sx={{ marginLeft: "20px" }}>
                  <IconButton onClick={clearSearchParams} size="small">
                    <ClearIcon />
                  </IconButton>
                </Box>
              </Fade>
            </Box>
            <Button component={Link} to="create" variant="outlined">
              Create post
            </Button>
          </Box>
        </Toolbar>

        <Box
          sx={{
            marginLeft: "20px",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            marginBottom: "20px",
          }}
        >
          <Box
            sx={{
              margin: "10px 20px 0 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            Rubrics:
          </Box>
          {allTags?.map((i: Tags) => {
            return (
              <Box
                key={i.id}
                sx={{
                  marginRight: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "dotted 1px black",
                  borderRadius: "70px",
                  marginTop: "10px",
                }}
              >
                <Box sx={{ margin: "0 5px" }}>{i.tag}</Box>
                <Box>
                  <IconButton size="small">
                    <EditIcon
                      onClick={() => handleOpenEditTagDialog(i.tag, i.id)}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() => handleOpenConfirmationDialog(i.id, i.tag)}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            );
          })}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              marginTop: "10px",
            }}
            onClick={() => handleOpenEditTagDialog("", null)}
          >
            <AddIcon
              sx={{
                color: "#5DCB42",
              }}
            />
          </Box>
        </Box>

        <TableContainer>
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell size="small" sx={{ width: "10%" }} />
                <TableCell sx={{ width: "10%" }}>
                  <TableSortLabel
                    onClick={handleSort("id")}
                    active={sortBy === "id"}
                    direction={sortBy === "id" ? orderBy : "asc"}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: "40%" }}>
                  <TableSortLabel
                    onClick={handleSort("title")}
                    active={sortBy === "title"}
                    direction={sortBy === "title" ? orderBy : "asc"}
                  >
                    Title
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: "10%" }}>
                  <TableSortLabel
                    onClick={handleSort("created_at")}
                    active={sortBy === "created_at"}
                    direction={sortBy === "created_at" ? orderBy : "asc"}
                  >
                    Rubrics
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: "10%" }}>
                  <TableSortLabel
                    onClick={handleSort("created_at")}
                    active={sortBy === "created_at"}
                    direction={sortBy === "created_at" ? orderBy : "asc"}
                  >
                    Created at
                  </TableSortLabel>
                </TableCell>
                <TableCell size="small" align="right" />
              </TableRow>
            </TableHead>
            {data ? (
              <TableBody>
                {data?.data?.map((item: Post) => (
                  <PostRow
                    key={item.id}
                    item={item}
                    handleDelete={handleDelete}
                  />
                ))}
              </TableBody>
            ) : (
              skeletonArray.map((item, index: number) => (
                <TableRow key={index}>
                  {item.map((el: string[], ind: number) => (
                    <TableCell key={ind} component="th" scope="row">
                      <Skeleton animation="wave" variant="text" height={40} />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={data?.meta?.total || 0}
          rowsPerPage={perPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
};

export default Posts;
