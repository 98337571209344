import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import TodoRow from "./components/TodoRow";
// eslint-disable-next-line import/no-cycle
import { GlobalAnalyticData } from "../GlobalAnalytics/GlobalAnalytic";

type Props = {
  data: GlobalAnalyticData | null;
  handleClick: (block: string) => void;
};
const AnalyticTable: FC<Props> = ({ data, handleClick }) => {
  return (
    <TableContainer>
      <Table
        sx={{
          border: `1px solid rgba(224, 224, 224, 1)`,
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 50 }} />
            <TableCell sx={{ p: "5px 5px 5px" }}>
              <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">
                Analytic Data
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TodoRow title="todo" data={data?.todo} handleClick={handleClick} />
          <TodoRow
            title="planner"
            data={data?.planner}
            handleClick={handleClick}
          />
          <TodoRow
            title="garage"
            data={data?.garage}
            handleClick={handleClick}
          />
          <TodoRow title="login" data={data?.login} handleClick={handleClick} />
          <TodoRow
            title="archive"
            data={data?.archive}
            handleClick={handleClick}
          />
          <TodoRow
            title="network"
            data={data?.network}
            handleClick={handleClick}
          />
          <TodoRow title="plans" data={data?.plans} handleClick={handleClick} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AnalyticTable;
