import { FC } from "react";
import { TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Field } from "react-final-form";
import { FilmOptionType, BlogRubricFieldProps } from "./BlogRubricField.types";

const BlogRubricField: FC<BlogRubricFieldProps> = ({ optionsItem }) => {
  const filter = createFilterOptions<FilmOptionType>();

  return (
    <Field subscription={{ value: true }} name="data.tags">
      {({ input }) => (
        <Autocomplete
          id="tags"
          multiple
          {...input}
          onChange={(event, newValue: any) => {
            if (typeof newValue === "string") {
              input.onChange({
                name: newValue,
              });
            } else if (newValue && newValue.inputValue) {
              input.onChange({
                name: newValue.inputValue,
              });
            } else {
              input.onChange(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;

            const isExisting = options.some(
              (option) => inputValue === option.name
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                name: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={optionsItem}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.name;
          }}
          renderOption={(props, option) => <li {...props}>{option.name}</li>}
          sx={{ width: 300 }}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label="Blog rubric" />
          )}
        />
      )}
    </Field>
  );
};

export default BlogRubricField;
