import { FC } from "react";
import useSWR from "swr";
import { Box, TextField } from "@mui/material";
import { Field } from "react-final-form";
import TexEditor from "../../../../components/TextEditor";
import { useFetcher } from "../../../../hooks/useApi";
import { Tags } from "../../Post.types";
import UploadFileToPost from "./components/UploadFileToPost";
import UploadBlogBackground from "./components/UploadBlogBackground/UploadBlogBackground";
import BlogRubricField from "./components/BlogRubricField";

type PostItemFieldsProps = {
  description: string;
};

const PostItemFields: FC<PostItemFieldsProps> = ({ description }) => {
  const fetcher = useFetcher();

  const { data: allTags } = useSWR("/tags", fetcher);

  const optionsItem =
    allTags?.length > 0
      ? allTags.map((elem: Tags) => {
          return { name: elem.tag };
        })
      : [];

  return (
    <Box sx={{ padding: "0 20px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Field subscription={{ value: true }} name="data.seo_title">
          {({ input }) => (
            <TextField
              {...input}
              variant="standard"
              sx={{ marginRight: "40px" }}
              fullWidth
              label="Seo name"
            />
          )}
        </Field>

        <Field subscription={{ value: true }} name="data.seo_description">
          {({ input }) => (
            <TextField
              {...input}
              variant="standard"
              fullWidth
              label="Seo description"
            />
          )}
        </Field>
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Field subscription={{ value: true }} name="data.file">
          {({ input }) => (
            <UploadBlogBackground
              onChange={input.onChange}
              imageToken={input.value.token}
            />
          )}
        </Field>
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Field subscription={{ value: true }} name="data.title">
          {({ input }) => (
            <TextField {...input} variant="standard" fullWidth label="Title" />
          )}
        </Field>
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <BlogRubricField optionsItem={optionsItem} />
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <UploadFileToPost />
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Field subscription={{ value: true }} name="data.description">
          {({ input }) => <TexEditor {...input} value={description || ""} />}
        </Field>
      </Box>
    </Box>
  );
};

export default PostItemFields;
