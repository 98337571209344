import { useEffect } from "react";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { FormApi } from "final-form";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../hooks/useApi";
import DefaultForm from "../../../components/form/DefaultForm";
import PostToolBar from "../components/PostToolBar/PostToolBar";
import PostItemFields from "../components/PostItemFields";
import { CreatePostItemForm, Tags, TagsTwo } from "../Post.types";
import useCommon from "../../../hooks/useCommon";

const CreatePost = () => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { setBreadCrumbs } = useCommon();

  useEffect(() => {
    setBreadCrumbs?.([
      { label: "Posts", url: "/posts" },
      { label: "Create Post" },
    ]);
  }, [setBreadCrumbs]);

  const initialData = {
    data: {
      is_active: true,
      title: "",
      description: "",
      tags: [],
      seo_title: "",
      seo_description: "",
      file: undefined,
    },
  };

  const onSubmit = async (values: CreatePostItemForm, form: FormApi) => {
    if (!values.data.file) {
      enqueueSnackbar("Background photo is required", {
        variant: "error",
      });
      return;
    }
    const newData = {
      ...values.data,
      tags: values.data.tags.map((elem: TagsTwo | Tags) => {
        if (elem.inputValue) {
          return { name: elem.inputValue };
        }
        return elem;
      }),
      file: {
        token: values.data.file.token,
      },
    };
    try {
      const res = await api.post("/blog/posts", newData);
      form.reset();
      enqueueSnackbar("Post successful created", {
        variant: "success",
      });
      navigate(`/posts/${res.data.id}`);
    } catch (error) {
      enqueueSnackbar("Something want wrong", {
        variant: "error",
      });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <DefaultForm<CreatePostItemForm> data={initialData} onSubmit={onSubmit}>
        <PostToolBar isEdit={false} />
        <PostItemFields description="" />
      </DefaultForm>
    </Box>
  );
};

export default CreatePost;
