import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import AnalyticsFilters from "../filters";
// eslint-disable-next-line import/no-cycle
import AnalyticTable from "../AnalyticTable";
import { useApi } from "../../../../hooks/useApi";
import { AnalyticEnum, AnalyticTimePreset } from "../../analytic.enum";
import { AnalyticsFiltersForm } from "../../analitytics.types";
import DownloadAnalyticModal from "../../../../components/modals/DownloadAnalyticModal";

export type AnalyticItem = {
  [key: string]: number;
};

export type GlobalAnalyticData = {
  [key: string]: AnalyticItem;
};

type Props = {
  id?: number;
  title?: string;
  isSearch?: boolean;
};
const GlobalAnalytic: FC<Props> = ({
  id,
  title = "Global Analytic",
  isSearch = false,
}) => {
  const [searchParams, setSearchParams] = useState<AnalyticsFiltersForm>({
    search: "",
    dateFrom: null,
    dateTo: null,
    preset: AnalyticTimePreset.allTime,
  });

  const [data, setData] = useState<GlobalAnalyticData | null>(null);
  const api = useApi();
  const [loading, setLoading] = useState(true);

  const searchParamsString = useMemo(() => {
    return `${
      searchParams.dateFrom
        ? `date_time_from=${searchParams.dateFrom} 00:00:00&`
        : ""
    }${
      searchParams.dateTo ? `date_time_to=${searchParams.dateTo} 23:59:59&` : ""
    }${searchParams.search ? `search=${searchParams.search}&` : ""}${
      id ? `user_id=${id}` : ""
    }`;
  }, [searchParams]);

  const getAnalyticsData = async (block: string, isReset: boolean = false) => {
    if (isReset === false && data !== null && data[block] !== undefined) {
      if (Object.keys(data).length > 1) {
        const newData = { ...data };
        delete newData[block];
        setData(newData);
        return;
      }
      setData(null);
      return;
    }
    setLoading(true);
    const res: { data: AnalyticItem } = await api.get(
      `/user-info/${block}?${searchParamsString}`
    );
    setLoading(false);

    if (isReset) {
      setData({ [block]: res.data });
      return;
    }
    setData({ ...data, [block]: res.data });
  };

  const { showModal } = useModal();

  const handleOpenDownloadDialog = () => {
    const modal = showModal(DownloadAnalyticModal, {
      onCancel: () => {
        modal.hide();
      },
      searchParams: searchParamsString,
    });
  };

  useEffect(() => {
    getAnalyticsData(AnalyticEnum.todo, true);
  }, [searchParams]);

  return (
    <Box>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="primary" />
        </Backdrop>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="20px"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">{title}</Typography>
          <Box mt="5px">
            <Button onClick={handleOpenDownloadDialog}> Export </Button>
          </Box>
        </Box>
        <Box>
          <AnalyticsFilters
            isSearch={isSearch}
            handleSearch={(form) => setSearchParams(form)}
          />
        </Box>
      </Box>
      <Box>
        <AnalyticTable
          data={data}
          handleClick={(block) => getAnalyticsData(block)}
        />
      </Box>
    </Box>
  );
};

export default GlobalAnalytic;
