import React, { FC } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { itemTitleKey } from "../../itemTitleKeyObject.types";

type Props = {
  title: string;
  data: { [key: string]: number } | undefined;
  handleClick: (block: string) => void;
};

const TodoRow: FC<Props> = ({ title, data, handleClick }) => {
  const theme = useTheme();
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell
          sx={{
            p: "2px",
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleClick(title)}
          >
            {data ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          sx={{
            p: "2px",
          }}
          component="th"
          scope="row"
        >
          <Typography
            sx={{
              fontWeight: data !== undefined ? "bold" : "initial",
              color:
                data !== undefined ? theme.palette.primary.main : "initial",
            }}
            variant="subtitle1"
          >
            {title}
          </Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={data !== undefined} timeout="auto" unmountOnExit>
            <Box sx={{ p: "10px 0 10px 35px" }}>
              {data && (
                <Table
                  sx={{
                    border: `1px solid rgba(224, 224, 224, 1)`,
                    borderRadius: "5px",
                  }}
                  size="small"
                  aria-label="purchases"
                >
                  <TableHead>
                    <TableRow sx={{ background: theme.palette.action.hover }}>
                      {Object.keys(data).map((item, index) => (
                        <TableCell key={index}>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                            }}
                            variant="body1"
                          >
                            {itemTitleKey[item]}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {Object.values(data).map((item, index) => (
                        <TableCell key={index}>
                          <Typography variant="body1">{item}</Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TodoRow;
