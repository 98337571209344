import React, { FC, useMemo } from "react";
import useSWR from "swr";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box } from "@mui/material";
import { Field } from "react-final-form";
import { useFetcher } from "../../../../hooks/useApi";
import { plansKey } from "../../../../pages/OnboardingData/titleKeyObject.types";

const DownloadAnalyticModalFields: FC = () => {
  const fetcher = useFetcher();

  const { data } = useSWR("/user-info/export/config", fetcher);

  const typesOptions = useMemo(() => {
    return data?.body.types.length > 0 ? data?.body.types : [];
  }, [data?.body.types]);

  const blocksOptions = useMemo(() => {
    return data?.body.blocks.length > 0 ? data?.body.blocks : [];
  }, [data?.body.blocks]);

  const userPackagesOptions = useMemo(() => {
    return data?.body.user_packages.length > 0 ? data?.body.user_packages : [];
  }, [data?.body.user_packages]);

  return (
    <Box
      sx={{
        padding: "0 20px",
        marginTop: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <Field subscription={{ value: true }} name="data.types">
          {({ input }) => (
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-name-label">File type</InputLabel>
              <Select
                {...input}
                required
                labelId="demo-multiple-name-label"
                id="types"
                multiple
                input={<OutlinedInput label="File type" />}
                style={{ minWidth: "200px" }}
              >
                {typesOptions.map((option: string) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Field>
      </Box>

      <Box>
        <Field subscription={{ value: true }} name="data.blocks">
          {({ input }) => (
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-name-label">Block</InputLabel>
              <Select
                required
                {...input}
                labelId="demo-multiple-name-label"
                id="blocks"
                input={<OutlinedInput label="Block" />}
                style={{ minWidth: "200px" }}
              >
                {blocksOptions.map((option: string) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Field>
      </Box>

      <Box>
        <Field subscription={{ value: true }} name="data.user_packages">
          {({ input }) => (
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-name-label">
                User packages
              </InputLabel>
              <Select
                {...input}
                labelId="demo-multiple-name-label"
                id="user_packages"
                multiple
                required
                input={<OutlinedInput label="User packages" />}
                style={{ minWidth: "200px" }}
              >
                {userPackagesOptions.map((option: string) => (
                  <MenuItem key={option} value={option}>
                    {plansKey[option]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Field>
      </Box>
    </Box>
  );
};

export default DownloadAnalyticModalFields;
