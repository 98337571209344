import Dialog, { DialogProps } from "@mui/material/Dialog";
import React, { FC, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { FormApi, ValidationErrors } from "final-form";

import { useSnackbar } from "notistack";
import * as yup from "yup";
import DefaultForm from "../../form/DefaultForm";
import DownloadAnalyticModalFooter from "./DownloadAnalyticModalFooter";
import { useApi } from "../../../hooks/useApi";
import DownloadAnalyticModalFields from "./components/DownloadAnalyticModalFields";
import { plansKey } from "../../../pages/OnboardingData/titleKeyObject.types";

type DownloadAnalyticFormatDate = {
  data: {
    blocks: string[];
    types: string[];
    user_packages: string[];
  };
};

type Props = DialogProps & {
  onCancel: () => void;
  searchParams: string;
};

const DownloadAnalyticModal: FC<Props> = ({ onCancel, ...props }) => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (
    values: DownloadAnalyticFormatDate,
    form: FormApi
  ) => {
    try {
      setLoading(true);
      let fileName = "global-analytic_plan";

      values.data.user_packages.forEach((item: string) => {
        fileName += `-${plansKey[item]}`;
      });

      if (values.data.types.length === 1 && values.data.types[0] === "Xls") {
        fileName += `.${values.data.types[0].toLowerCase()}`;
      }

      if (values.data.types.length === 1 && values.data.types[0] === "Csv") {
        fileName += `.${values.data.types[0].toLowerCase()}`;
      }

      const renderUrl = () => {
        let url = `/user-info/export?${props.searchParams}&info_blocks[0][name]=${values?.data?.blocks}`;

        values.data.types.forEach((item: string) => {
          url += `&info_blocks[0][types][]=${item}`;
        });

        values.data.user_packages.forEach((item: string) => {
          url += `&user_packages[]=${item}`;
        });

        return url;
      };

      setLoading(false);

      const response = await api.get(renderUrl(), {
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();

      form.reset();
      onCancel();
    } catch (e) {
      enqueueSnackbar("Something want wrong", {
        variant: "error",
      });
    }

    return "";
  };
  const initialValue: DownloadAnalyticFormatDate = {
    data: {
      blocks: [],
      types: [],
      user_packages: [],
    },
  };

  const validationSchema: yup.SchemaOf<any> = yup.object().shape({
    data: yup.object().shape({
      types: yup.string().required(),
      blocks: yup.string().required(),
      user_packages: yup.string().required(),
    }),
  });

  const validate = (
    values: DownloadAnalyticFormatDate
  ): Promise<ValidationErrors | undefined> =>
    validationSchema
      .validate(values)
      .then(() => undefined)
      .catch((error: yup.ValidationError) => {
        const errors: { [value: string]: string } = {};
        error.inner.forEach((e) => {
          if (e.path) {
            errors[e.path] = e.message;
          }
        });
        return errors;
      });

  return (
    <Dialog maxWidth="md" fullWidth {...props}>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="primary" />
        </Backdrop>
      )}
      <DefaultForm<DownloadAnalyticFormatDate>
        data={initialValue}
        validate={validate}
        isValidate
        onSubmit={onSubmit}
      >
        <DialogTitle id="alert-dialog-title">Download</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <DownloadAnalyticModalFields />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DownloadAnalyticModalFooter onCancel={onCancel} />
        </DialogActions>
      </DefaultForm>
    </Dialog>
  );
};

export default DownloadAnalyticModal;
